import React from 'react';

// Project imports
import { Thought } from './Thought'

// CSS
import './Thoughts.css'

export interface ThoughtsProps {
    PostId?: number;
}

export const Thoughts: React.FC<ThoughtsProps> = (props) => {
    return <div className="Thoughts">
        <Thought Id={"rJaiGw3XitdhDVNIdkTy"}/>
    </div>
}