import React from 'react';
import Firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/firestore'

const FirebaseConfig = {
        apiKey: "AIzaSyCzZFHrZuJVTMlRrcbG32lxhNyvB-dULYw",
        authDomain: "masonliyucom.firebaseapp.com",
        databaseURL: "https://masonliyucom.firebaseio.com",
        projectId: "masonliyucom",
        storageBucket: "masonliyucom.appspot.com",
        messagingSenderId: "571208644401",
        appId: "1:571208644401:web:69bab2806e8a8bf4562210",
        measurementId: "G-RXM6TEB2CC"
};

// We only want to initialize these things once on import.
Firebase.initializeApp(FirebaseConfig);
Firebase.analytics();
Firebase.firestore().enablePersistence()
    .catch((err) => {
        console.log(`Couldn't enable offline reads due to ${err.code}.`)
    });

export const FirebaseContext = React.createContext(Firebase);

export const FirebaseProvider: React.FC = (props) => {
    return <FirebaseContext.Provider value={Firebase}>
        {props.children}
    </FirebaseContext.Provider>
}