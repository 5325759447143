import React from 'react';

import "@material/tab/dist/mdc.tab.css";

export enum HeaderTabIcon {
    access_time,
    attach_money,
    emoji_people,
    keyboard,
}

export interface HeaderTabProps {
    Name: string;
    Icon: HeaderTabIcon;
    Active?: boolean;
    onClick?: React.MouseEventHandler;
}

export const HeaderTab: React.FC<HeaderTabProps> = (props) => {
    let tabClass = "mdc-tab"
    let tabIndicatorClass = "mdc-tab-indicator"
    if (props.Active) {
        tabClass += " mdc-tab--active"
        tabIndicatorClass += " mdc-tab-indicator--active"
    }
    return <button className={tabClass} role="tab" aria-selected="true" onClick={props.onClick}>
        <span className="mdc-tab__content">
            <span className="mdc-tab__icon material-icons" aria-hidden="true">{HeaderTabIcon[props.Icon]}</span>
            <span className="mdc-tab__text-label">{props.Name}</span>
        </span>
        <span className={tabIndicatorClass}>
            <span className="mdc-tab-indicator__content mdc-tab-indicator__content--underline"></span>
        </span>
        <span className="mdc-tab__ripple"></span>
    </button>;
}