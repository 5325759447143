import React, { useReducer } from "react";
import * as Selection from "../../reducers/Selection/Selection" 

// Make state types, action types, and action-related enums available via re-export in this module.
export { Selection };

const defaultState: Selection.SelectionState = {
    Page: Selection.SelectedPage.Thoughts,
}
type SelectionContextType = [Selection.SelectionState, React.Dispatch<Selection.SelectionAction>] | null

export const SelectionContext = React.createContext<SelectionContextType>(null)

export const SelectionProvider: React.FC = (props) => {
    const [state, dispatch] = useReducer(Selection.SelectionReducer, defaultState);

    return <SelectionContext.Provider value={[state, dispatch]} >
        <div>
            <div>
                { props.children }
            </div>
        </div>
    </SelectionContext.Provider>;
}