import React from 'react';
import DOMPurify from 'dompurify';
import marked from 'marked';

import { FirebaseContext } from '../../contexts/contexts';

// CSS
import './Thought.css'

export interface ThoughtProps {
    Id: string;
}

interface ThoughtState {
    markdown: string; 
}

const defaultString = ''
const defaultThoughtState: ThoughtState = {
    markdown: defaultString,
}

export const Thought: React.FC<ThoughtProps> = (props) => {
    const [state, setState] = React.useState<ThoughtState>(defaultThoughtState);
    const firebase = React.useContext(FirebaseContext);
    const db = firebase.firestore();
    React.useMemo(() => {
       const thoughtRef = db.collection("thoughts").doc(props.Id);
       thoughtRef.get().then((thought) => {
           const thoughtData = thought.data();
           if (thoughtData === undefined) {
               setState({
                   markdown: '# Database error!',
               })
           } else {
               setState({
                   markdown: thoughtData.markdown,
               })
           }
       })
    }, [props.Id, db]);

    const markdownHTML = {
        __html: DOMPurify.sanitize(marked(state.markdown)),
    }

    if (state.markdown === '') {
        return null;
    }

   return <div className="Thought" dangerouslySetInnerHTML={markdownHTML}/>;
}