import React from 'react';
import './css/App.css';

// Project imports
import { FirebaseProvider } from './components/FirebaseProvider/FirebaseProvider';
import { SelectionProvider } from './components/SelectionProvider/SelectionProvider';
import { Header } from './components/Header/Header';
import { Stage } from './components/Stage/Stage';

const App: React.FC = () => {
  return (
    <div className="App">
      <FirebaseProvider>
        <SelectionProvider>
          <Header />
          <Stage />
        </SelectionProvider>
      </FirebaseProvider>
    </div>
  );
}

export default App;
