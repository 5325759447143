import React, { useEffect, useContext } from 'react';
import { MDCTabBar } from '@material/tab-bar';

// Project imports
import { HeaderTab, HeaderTabIcon, HeaderTabProps } from './HeaderTab';
import { SelectionContext, Selection } from '../SelectionProvider/SelectionProvider'

import "@material/tab-bar/dist/mdc.tab-bar.css";
import "@material/tab-scroller/dist/mdc.tab-scroller.css";
import "@material/tab-indicator/dist/mdc.tab-indicator.css";

const initiateMDCTabBar: React.EffectCallback = () => {
    const tabBarElt = document.querySelector('.mdc-tab-bar') 
    if (tabBarElt == null) {
        return
    }
    const tabBar = new MDCTabBar(tabBarElt);

    return () => {
        tabBar.destroy();
    };
}

const SwapPageClickHandler: (newPage: Selection.SelectedPage, dispatch: React.Dispatch<Selection.SelectionAction>) => React.MouseEventHandler = (newPage, dispatch) => {
  const action: Selection.SelectionAction = {
    Type: Selection.ActionType.SetPage,
    NewPage: newPage,
  };

  return (event) => {
    event.preventDefault();
    dispatch(action);
  }
}

interface buttonSpecification extends HeaderTabProps {
  Page: Selection.SelectedPage;
}

export const Header: React.FC = (props) => {
    useEffect(initiateMDCTabBar);
    const selectionContext = useContext(SelectionContext);

    let contextAvailable = false;
    let dispatch: React.Dispatch<Selection.SelectionAction>;
    if (selectionContext != null) {
      dispatch = selectionContext[1];
      contextAvailable = true;
    }

    const buttonSpecs: Array<buttonSpecification> = [
      {
        Name: "Thoughts",
        Icon: HeaderTabIcon.keyboard,
        Page: Selection.SelectedPage.Thoughts,
      },
      {
        Name: "Time",
        Icon: HeaderTabIcon.access_time,
        Page: Selection.SelectedPage.Time,
      },
      {
        Name: "Money",
        Icon: HeaderTabIcon.attach_money,
        Page: Selection.SelectedPage.Money,
      },
      {
        Name: "Me",
        Icon: HeaderTabIcon.emoji_people,
        Page: Selection.SelectedPage.Me,
      },
    ];

    return <div className="mdc-tab-bar" role="tablist">
    <div className="mdc-tab-scroller">
      <div className="mdc-tab-scroller__scroll-area">
        <div className="mdc-tab-scroller__scroll-content">
          { buttonSpecs.map((buttonSpec => {
            if (contextAvailable) {
              return <HeaderTab Name={buttonSpec.Name} Icon={buttonSpec.Icon} Active={buttonSpec.Active} onClick={SwapPageClickHandler(buttonSpec.Page, dispatch)} key={buttonSpec.Page}/>
            }
            return <HeaderTab Name={buttonSpec.Name} Icon={buttonSpec.Icon} Active={buttonSpec.Active} key={buttonSpec.Page}/>
          }))}
        </div>
      </div>
    </div>
  </div> 
}