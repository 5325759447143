import React, { useContext } from 'react';

// Project imports
import { Thoughts } from '../Thoughts/Thoughts';
import { Selection, SelectionContext } from '../SelectionProvider/SelectionProvider';

// CSS
import './Stage.css'

export const Stage: React.FC = (props) => {
    const selectionContext = useContext(SelectionContext);

    let page: Selection.SelectedPage;

    if (selectionContext == null) {
        // Show Thoughts page by default if we're not initialized.
        page = Selection.SelectedPage.Thoughts
    } else {
        page = selectionContext[0].Page
    }
    let body: React.ReactElement;

    switch(page) {
        case Selection.SelectedPage.Thoughts:
            body = <Thoughts />
            break;
        case Selection.SelectedPage.Me:
            body = <div>
                Me!
            </div>
            break;
        case Selection.SelectedPage.Money:
            body = <div>
                Money?
            </div>
            break;
        case Selection.SelectedPage.Time:
            body = <div>
                Time...
            </div>
            break;
        default:
            body = <div>
                Empty page!
            </div>;
    }

    return <div className={"Stage"}>
        {body}
    </div>;
}