// Selection.ts
// Defines types, interfaces, and the reducer definition for modifying global page state.
import React from "react";

// Indicates which pages are valid.
export enum SelectedPage {
    Thoughts,
    Time,
    Money,
    Me,
}

// Values for SelectionAction.Type.
export enum ActionType {
    SetPage
}

// State for the reducer.
export interface SelectionState {
    Page: SelectedPage;
}

// Action for the reducer.
export interface SelectionAction {
    Type: ActionType;
    NewPage?: SelectedPage;
}

export type SelectionReducerType = React.Reducer<SelectionState, SelectionAction>;

export const SelectionReducer: SelectionReducerType = (prevState, action) => {
    let newState: SelectionState;
    switch(action.Type) {
        case ActionType.SetPage:
            if (action.NewPage === undefined) {
                throw new Error(`SelectionReducer() called with SetPage without SelectionAction.NewPage set.`)
            }
            newState = {
                Page: action.NewPage,
            }
            break;
        default:
            throw new Error(`SelectionReducer() called with unknown action type ${ActionType[action.Type]}`);
    }

    return newState;
}
